import { getConfig } from '../config/config.js';
import authHeader from '../helpers/jwtHelpers.js';

export async function generateOtp(data) {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });
  const res = await fetch(`${getConfig().authHost}/user/otp/generate/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  if (res.status === 200) {
    return res.json();
  } else {
    let errorMessage = 'Please enter a valid Mobile Number';
    try {
      const response = await res.json();
      if (response.detail) {
        errorMessage = response.detail;
      }
    } catch {}
    throw new Error(errorMessage);
  }
}

export async function verifyOtp(data) {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });
  const res = await fetch(`${getConfig().authHost}/user/otp/validate/`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  if (res.status === 200) {
    const user = res.json();
    return user;
  } else {
    throw new Error('Please enter a valid OTP');
  }
}

export async function getUser(token = '') {
  let headers = await authHeader();
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }
  const res = await fetch(`${getConfig().authHost}/user/`, {
    method: 'GET',
    headers,
  });
  if (res.status === 200) {
    return res.json();
  } else {
    throw res;
  }
}

export async function deleteUser(id) {
  const headers = await authHeader();
  const res = await fetch(`${getConfig().authHost}/user/${id}/`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({}),
  });
  if (res.status === 202) {
    return res.json();
  } else {
    throw res;
  }
}
