export default {
  web: 'https://stag2-web.orangehealth.dev',
  globalDomain: 'orangehealth.dev',
  home: 'https://stag2-www.orangehealth.dev',
  geoMarkHost: 'https://stag5-geomark-api.orangehealth.dev/api',
  authHost: 'https://stag2-accounts.orangehealth.dev/api/v1',
  apiHost_: 'https://stag2-clr-api.orangehealth.dev/api/v1',
  apiHost: 'https://stag2-api.orangehealth.dev/api/v1',
  apiHostV2: 'https://stag2-api.orangehealth.dev/api/v2',
  apiHostV3: 'https://stag2-api.orangehealth.dev/api/v3',
  cerebroHost: 'https://stag2-cerebro.orangehealth.dev/api/v1',
  omsApiHost: 'https://stag2-oms-api.orangehealth.dev/api',
  scheduler: 'https://stag2-scheduler-api.orangehealth.dev/api/v1',
  agoraId: '8178f71742db4128a07f17fdce9dcf52',
  bugSnag: '',
  bugSnagWeb: '3a11fc4989861960eaab2b184816a80c',
  vapidKey: {
    vapidKey:
      'BFtwIGesEzN6LMVPNS2zg70ML3Tw3-wuMvJswxQ4LoHwvV2zeTsfYhuPr0LLIndHHyJRX-AcX3hOPwTkz50_h5k',
  },
  gcpWebProjectId: 'orangehealth-dev-maps',
  gcpWebProjectKey: 'AIzaSyAdcKGn1l36A1Unv0wydP-d0kDNCHvFkS8',
  gcpAppAutocompleteKey: 'AIzaSyAdcKGn1l36A1Unv0wydP-d0kDNCHvFkS8',
  mixpanelProjectToken: 'e0414c91421553a948eab60bb1368ea3',
  fbPixelKey: '',
  mixpanelWebAppProjectToken: 'e0414c91421553a948eab60bb1368ea3',
  mixpanelCustomerAppProjectToken: '0f35d51a7cadf84b8ef2b3aeac887b0e',
  mixpanelDoctorAppProjectToken: 'be5f58fb9cf48d7786bc91cdc7221084',
  gaTagMangerId: 'GTM-N9Z7LRS',
  appsFlyerDevKey: '9ouE4xoaffU2SVdj8yDpJc',
  appsFlyerAppId: 'id1534689588',
  customerEmedicBridge: {
    enabled: true,
    bridgeNumber: '+918069454196',
  },
  cleverTapId: 'TEST-944-869-R66Z',
  smartLookAppProjectToken: '6ef63c6640a8b53c38bc053e9d7caeb2ee4afd23',
  appName: 'OH-Patient',
  appVersion: '4.1.4',
  smartLookKey: '02066dab1ea8d0e2401e87de880932ff24268666',
};
