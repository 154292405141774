import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { logoutFirebase, removeMessagingToken } from '../actions/authActions';

export const USER = 'USER';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const IS_INTRO_SHOWN = 'IS_INTRO_SHOWN';
export const APP_TYPE = 'APP_TYPE';
export const SKIP_UPDATE = 'SKIP_UPDATE';
export const STATE = 'STATE';
export const CURRENT_LOCATION = 'CURRENT_LOCATION';
export const IS_LOCATION_DIALOG_SHOWN = 'IS_LOCATION_DIALOG_SHOWN';
export const IS_APPLICATION_RATED = 'IS_APPLICATION_RATED';
export const ORDER_COUNT = 'ORDER_COUNT';
export const ALL_CITIES = 'ALL_CITIES';
export const ALL_PARENT_CITIES = 'ALL_PARENT_CITIES';
export const USER_PERMISSION_CONSENT = 'USER_PERMISSION_CONSENT';
export const HAS_MIGRATED_FROM_ASYNC_STORAGE =
  'HAS_MIGRATED_FROM_ASYNC_STORAGE';
export const APP_VERSION = 'APP_VERSION';

const isAPP = () => {
  return Platform.OS === 'android' || Platform.OS === 'ios';
};

export const setItem = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getItem = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
};

export const removeItem = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (_e) {}
};

async function clearAsycStorage(appOverride = false) {
  if (isAPP() && !appOverride) {
    return;
  }
  const asyncStorageKeys = await AsyncStorage.getAllKeys();
  if (asyncStorageKeys.length > 0) {
    let filteredKeys = asyncStorageKeys.filter(
      (key) =>
        key != USER_PERMISSION_CONSENT || key != HAS_MIGRATED_FROM_ASYNC_STORAGE
    );
    await AsyncStorage.multiRemove(filteredKeys);
  }
}

export const clearLocalStorage = async (appOverride = false) => {
  if (isAPP() && !appOverride) {
    return;
  }
  try {
    const user = await getItem(USER);
    if (user) {
      await removeMessagingToken(user.user_id);
      await logoutFirebase();
    }
    await clearAsycStorage(appOverride);
  } catch (e) {
    // clear error
    await clearAsycStorage(appOverride);
  }
};

export const loadState = async () => {
  try {
    const serializedState = await getItem(STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = async (state) => {
  try {
    const serializedState = JSON.stringify(state);
    await setItem(STATE, serializedState);
  } catch {
    // ignore write errors
  }
};
