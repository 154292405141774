export function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

export function setCookie(name, value, expiresInDays=1) {
  try {
    let newCookieStr = name + "=" + value + ";";
    if (expiresInDays) {
      const currentDate = new Date();
      currentDate.setTime(currentDate.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
      const expires = "expires="+currentDate.toUTCString();
      newCookieStr = newCookieStr + expires + ";path=/";
    }
    document.cookie =  newCookieStr;
  } catch {}
}

export function deleteCookie(name) {
  // setting expiry date to past date results in cookie being deleted
  try {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    console.log('deleting cookie')
  } catch {}
}

export function clearAllCookies() {
  if (typeof document === 'undefined') {
    return
  }
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}